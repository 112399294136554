<template>
  <div class="page-container">
    <div class="banner">
      <img class="img" src="../../assets/contact/banner.png" alt="" />
      <div class="banner-title">
        <h2>{{t("company.contact.banner_title")}}</h2>
      </div>
    </div>
    <section class="sec-w-100">
      <div class="d-w-100">
        <div class="d-w-50">
          <div class="d-item">
            <h3>{{t("company.contact.city1")}}</h3>
            <div class="d-addr-1">{{t("company.contact.addr1")}}</div>
            <div class="d-addr-2">{{t("company.contact.email1")}} info@worldspire.com</div>
          </div>
          <div class="d-item">
            <h3>{{t("company.contact.city2")}}</h3>
            <div class="d-addr-1">{{t("company.contact.addr2")}}</div>
          </div>
          <div class="d-item">
            <h3>{{t("company.contact.city3")}}</h3>
            <div class="d-addr-1">{{t("company.contact.addr3")}}</div>
          </div>
          <div class="d-item">
            <h3>{{t("company.contact.city4")}}</h3>
            <div class="d-addr-1">{{t("company.contact.addr4")}}</div>
            <!-- <div class="d-addr-2">{{t("company.contact.email2")}}</div> -->
          </div>
          <div class="d-item">
            <h3>{{t("company.contact.city5")}}</h3>
            <div class="d-addr-1">{{t("company.contact.addr5")}}</div>
          </div>
          <div class="d-item">
            <h3>{{t("company.contact.city6")}}</h3>
            <div class="d-addr-1">{{t("company.contact.addr6")}}</div>
          </div>
        </div>
        <div class="d-w-50">
          <!-- <div class="help-form">
                <div class="form">
                    <div class="input-box">
                        <span class="require">*</span>
                        <input class="require-input" v-model="form.firstName" type="text" :placeholder="t('form.firstName')">
                    </div>
                    <div class="input-box">
                        <span class="require">*</span>
                        <input class="require-input" type="text" :placeholder="t('form.lastName')">
                    </div>
                    <div class="input-box">
                        <span class="require">*</span>
                        <input class="require-input" type="text" :placeholder="t('form.email')">
                    </div>
                    <div class="input-box">
                        <input class="require-input" type="text" :placeholder="t('form.phone')">
                    </div>
                    <div class="input-box">
                        <span class="require">*</span>
                        <input class="require-input" type="text" :placeholder="t('form.company')">
                    </div>
                    <div class="input-box">
                        <span class="require">*</span>
                        <input class="require-input" type="text" :placeholder="t('form.job')">
                    </div>
                    <div class="input-box">
                        <span class="require">*</span>
                        <input class="require-input" type="text" :placeholder="t('form.country')">
                    </div>
                    <div class="input-box textarea">
                        <textarea class="require-input" type="text" :placeholder="t('form.comments')"></textarea>
                    </div>
                    <div class="input-box btn">
                        <button @click="submitData">{{t('form.btnMessage')}}</button>
                    </div>
                </div>
          </div> -->
           <div class="help-form">
                <div class="form">
                    <div class="input-box">
                        <span class="require">*</span>
                        <input class="require-input" :class="{active: active, error: !form.firstName.trim()}" v-model="form.firstName" type="text" :placeholder="t('form.firstName')">
                    </div>
                    <div class="input-box">
                        <span class="require">*</span>
                        <input class="require-input" :class="{active: active, error: !form.lastName.trim()}" v-model="form.lastName" type="text" :placeholder="t('form.lastName')">
                    </div>
                    <div class="input-box">
                        <span class="require">*</span>
                        <input class="require-input" :class="{active: active, error: !form.email.trim() || emailError}" @keyup="inputChange($event)" v-model="form.email" type="text" :placeholder="t('form.email')">
                    </div>
                    <div class="input-box">
                        <!-- <span class="require">*</span> -->
                        <input class="require-input" v-model="form.phone" type="text" :placeholder="t('form.phone')">
                    </div>
                    <div class="input-box">
                        <span class="require">*</span>
                        <input class="require-input" :class="{active: active, error: !form.company.trim()}" v-model="form.company" type="text" :placeholder="t('form.company')">
                    </div>
                    <div class="input-box">
                        <span class="require">*</span>
                        <input class="require-input" :class="{active: active, error: !form.job.trim()}" v-model="form.job" type="text" :placeholder="t('form.job')">
                    </div>
                    <div class="input-box">
                        <span class="require">*</span>
                        <input class="require-input" :class="{active: active, error: !form.country.trim()}" v-model="form.country" type="text" :placeholder="t('form.country')">
                    </div>
                    <div class="input-box textarea">
                        <!-- <span class="require">*</span> -->
                        <textarea class="require-input" v-model="form.comments" type="text" :placeholder="t('form.comments')"></textarea>
                    </div>
                    <div class="input-box btn">
                        <button @click="submit">{{t('form.btnMessage')}}</button>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import {ref, reactive} from 'vue'
import { useI18n } from "vue-i18n";
import { addContact } from '../../api/api'
export default {
    name: 'CONTACT',
    setup() {
        const { t } = useI18n();
        let form = reactive({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            company: '',
            job: '',
            country: '',
            comments: ''
        })
        let err = reactive({
            firstName: false,
            lastName: false,
            email: false,
            company: false,
            job: false,
            country: false
        })
        let active = ref(false)
        let emailError = ref(false)
        let isSuccess = ref(false)
        return {
          t,
          form,
          err,
          active,
          emailError,
          isSuccess,
        }
    },
    methods: {
      inputChange (e) {
            let reg =  new RegExp(/\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/);
            if (!reg.test(e.target.value)) {
                this.emailError = true
            } else {
                this.emailError = false
            }
        },
        submit() {
            this.active = true
            let reg = /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
            this.err = {
                firstName: !this.form.firstName.trim(),
                lastName: !this.form.lastName.trim(),
                email: !this.form.email.trim() || !reg.test(this.form.email.trim()),
                company: !this.form.company.trim(),
                job: !this.form.job.trim(),
                country: !this.form.country.trim()
            }
            if (Object.keys(this.err).map(e => { return this.err[e] }).some(e=>{return e}) || this.emailError) {
                return
            }
            let data = {
                firstname: this.form.firstName,
                lastname: this.form.lastName,
                email: this.form.email,
                phone: this.form.phone,
                company: this.form.company,
                jobTitle: this.form.job,
                country: this.form.country,
                comments: this.form.comments
            }
            addContact(data).then(res => {
                if (res.code == 200) {
                  var user_id= localStorage.getItem('userId');
                  dataLayer.push({
                      'event':'purchase',
                      'userId':user_id,
                      'action':'Submit',
                      'currency': 'USD',
                      'transaction_id': 'WS_0001',
                      'value': 0,
                      'items': [
                          {
                          'item_id': 'World_Spire',
                          'item_name': 'WorldSpire',
                          }
                      ]
                  });
                  this.isSuccess = true
                }
            })
        }
    },
}
</script>
<style lang="less" scoped>
.page-container {
  .banner {
    display: block;
    position: relative;
    z-index: 0;
    background: #000;
    overflow: hidden;
    height: 400px;
    width: 100%;
    .img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .banner-title {
      color: #F4F5F5;
      font-weight: 400;
      font-size: 60px;
      position: absolute;
      top: 50%;
      left: 22%;
      // transform: translateY(-50%);
      &::after {
        content: '';
        display: block;
        width: 140px;
        margin: 15px 0;
        border-bottom: 8px solid #FF9333;
        border-radius: 4px;
      }
    }
  }
  .sec-w-100 {
    width: 100%;
    position: relative;
    z-index: 10;
    .d-w-100 {
      // width: 100%;
      // padding: 30px 80px;
      width: 1140px;
      padding: 30px 0;
      margin: 0 auto;
      display: flex;
      .d-w-50 {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        .d-item {
          width: 50%;
          padding: 20px 40px 20px 0;
          line-height: 26px;
          .d-addr-1,.d-addr-2 {
            font-size: 14px;
            color: #333333;
          }
        }
      }
      .help-form {
        width: 530px;
        // height: e("calc(100% + 65px)");
        height: 100%;
        position: relative;
        // top: -65px;
        left: 100px;
        z-index: 100;
        background: #ffffff;
        box-shadow: 0px 0px 20px 0px rgba(16, 81, 170, 0.1);
        .form {
            width: 455px;
            margin: 30px auto;
            // margin: 35px 0 0 70px;
            &::after {
                content: '';
                display: block;
                clear: both;
            }
            input {
                background: #FFFFFF;
                border-radius: 2px;
                border: 1px solid #cccccc;
                width: 220px;
                height: 40px;
                padding: 0 30px;
                &.active.error {
                  border-color: #ff0000;
                }
            }
            textarea {
                width: 100%;
                height: 100px;
                border-radius: 2px;
                border: 1px solid #cccccc;
                padding: 20px 30px;
            }
            .input-box {
                position: relative;
                float: left;
                margin: 0 15px 15px 0;
                &:nth-child(2n) {
                    margin-right: 0;
                }
                .require {
                    position: absolute;
                    left: 20px;
                    top: 12px;
                    color: #ff0000;
                }
                &.textarea {
                    width: 100%;
                }
                &.btn {
                  position: absolute;
                  bottom: 30px;
                  button { 
                      width: 190px;  
                      height: 50px;
                      line-height: 50px;
                      text-align: center;
                      font-size: 18px;
                      color: #ffffff;
                      border: none;
                      background: #ff9333;
                      border-radius: 24px;
                      cursor: pointer;
                      &:active {
                          background: rgba(255, 147, 51, 0.7);
                      }
                  }
                }
            }
        }
      }
    }
  }
}
</style>
